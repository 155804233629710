.contact {
    float: left;
    background-color: #eee;
}
.contact .errors {
    color: white;
    background-color: #E74C3C;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.contact .thanks {
    float: left;
    width: 100%;
    color:green;
    text-align: center;
    font-size: 22px;
    padding: 40px 0px;
}
.contact-box {
    float: left;
    width: 50%;
}
.contact-box-content {
    margin: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}
.contact h1 {
    color: #222;
    padding: 5px;
}
.contact h2 {
    color: #F39C12;
    margin: 10px;
}
.contact-box p {
    color: #222021;
    padding: 10px;
}
.contact input, .contact textarea {
    padding: 10px;
    margin: 10px 0px;
    border: none;
    width: 100%;
    background-color: transparent;
    color: #222;
    background-color: #eee;
    resize: none;
    border-radius: 5px;
    border: 1px solid #ddd;
}
.contact input:focus {
    outline: none;
}
.contact textarea:focus {
    outline: none;
}
@media screen and (max-width: 720px) {
    .contact-box {
        width: 100%;
    }
}