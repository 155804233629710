.about {
    float: left;
    background-color: #eee;
}
.about .about-box {
    float: left;
    width: 50%;
    padding: 10px;
}
.about .about-box-content {
    float: left;
    width: 100%;
    height: 300px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}
.about .about-title{
    background-color: #eee;
    border: 1px solid #ddd;
    float: left;
    padding: 5px 10px;
    border-radius: 5px;
}
.about .about-title img {
    float: left;
    height: 50px;
    width: 50px;
}
.about .about-title h1 {
    float: left;
    color: #222;
    font-size: 32px;
    line-height: 50px;
    margin-left: 10px;
}
.about p {
    float: left;
    color: #222;
    clear: both;
    padding: 10px 0px;
}

@media screen and (max-width: 720px){
    .about .about-box {
        width: 100%;
    }
}