.some-info {
    background-color: #F39C12;
    color: white;
}
.some-info h1, .some-info .each-info {
    float: left;
}
.some-info h1 {
    width: 50%;
    font-size: 32px;
}
.each-info {
    width: 16.66%;
}
.each-info p:first-child {
    font-size: 32px;
}
.each-info p:nth-child(2) {
    font-size: 16px;
}
@media screen and (max-width: 500px){
    .each-info{
        width: 100%;
    }
}