.footer,
.footer h3,
.footer p,.footer a,
.footer-content-side-box {
    float: left;
}
.footer {
    width: 100%;
    padding: 60px 0px;
    background-color: #222021;
    color: white;
}
.footer h3 {
    clear: both;
    font-weight: 100;
    padding-bottom: 10px;
}
.footer p {
    clear: both;
    padding: 5px 0px;
    color: #666;
}
.footer a {
    clear: both;
    padding: 5px 0px;
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}
.footer a:Hover {
    color: white;
}
.footer-content-side-box {
    width: 25%;
}
.footer img {
    width: 20px;
    height: 20px;
    margin-right:5px;
}
.activeF {
    color: #F39C12 !important;
}
@media screen and (max-width: 720px){
    .footer-content-side-box {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 10px;
        border-left: 2px solid #444;
    }
}