.careers {
    float: left;
    background-color: #eee;
}
.careers .job-box {
    float: left;
    width: 50%;
}
.careers .job-box-content {
    float: left;
    margin: 10px;
    padding: 0px 0px 10px 0px;
    background-color: white;
    border-radius: 5px;
}
.careers h2, .careers h3, .careers p {
    float: left;
    clear: both;
    padding: 0px 10px;
}
.careers h2 {
    background-color: #F39C12;
    color: white;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.careers h3 {
    color: #F39C12;
}
.careers p {
    color: #222021;
}