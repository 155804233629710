.faq {
    background-color: #ddd;
}
.faq .faq-box {
    float: left;
    width: 100%;
    background-color: white;
    cursor: pointer;
    border: 1px solid #aaa;
    transition: 0.25s;
    margin-top: 10px;
}
.faq .faq-box:Hover {
    box-shadow: 0px 2px 6px 2px #666;
}
.faq h1 {
    float: left;
    color: #222021;
    width: 100%;
    padding: 10px;
}
.faq-box div h2 {
    float: left;
    width: 80%;
    background-color: white;
    line-height: 60px;
    font-weight: 100;
    padding: 0px 10px;
    color:#222021;
}
.faq-box div span {
    float: left;
    width: 20%;
    background-color: #eee;
    text-align: center;
    height: 60px;
    position: relative;
}
.faq-box img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
}
.faq-box p {
    float: left;
    width: 100%;
    border-top: 1px solid #aaa;
    padding: 20px 10px;
    color: #222021;
}
.plus, .minus {
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.4s;
}
.plus{
    background-image: url('../../images/icons/plus.png');
}
.minus{
    background-image: url('../../images/icons/minus.png');
}
@media screen and (max-width: 720px){
    .faq-box div h2 {
        width: 100%;
    }
    .faq-box div span {
        width: 100%;
        border: none;
    }
    .faq-box p {
        border: none;
    }
}