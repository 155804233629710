.about-company {
  float: left;
  width: 100%;
  height: 500px;
}
.about-company-img {
  float: left;
  width: 100%;
  height: 500px;
  position: relative;
  text-align: center;
  background-image: url('../../images/company-img-1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 200px;
}
.about-company-text {
  position: absolute;
  width: 60%;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 100px;
  right: 0%;
  left: 0%;
  padding: 20px 0px;
}
.about-company p {
  padding: 10px 10px;
  color: #444;
  font-size: 20px;
}