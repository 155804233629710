.slide {
    height: 500px !important;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: #222;
}
.slide-img {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}
.slide-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.slide .content {
    position: relative;
    z-index: 2;
}