.check-list {
    background-color: #eee;
}
.check-list h1 {
    float: left;
    color: #222021;
    width: 100%;
    padding: 10px;
}
.check-list h2 {
    float: left;
    color: #aaa;
    width: 100%;
    padding: 0px 10px;
}
.check-list .each-check {
    float: left;
    width: 50%;
    margin: 2px 0px;
    padding: 5px;
    color: #222021;
}
.check-list .each-check img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}
@media screen and (max-width: 720px) {
    .check-list .each-check {
        width: 100%;
    }
}