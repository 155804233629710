/*font-family: 'Sarala', sans-serif;*/
body{font-family: 'Sarala', sans-serif;margin: 0 auto;width: 100%;height: 100%;}
*{padding: 0px auto;margin: 0px auto;box-sizing: border-box;}
.shadow{-moz-box-shadow: 0 0 8px #444;-webkit-box-shadow: 0 0 8px #444;box-shadow: 0 0 8px #444;}
.small-line{float: left;clear: both;border: 2px solid #F39C12;width: 80px;margin: 10px 0px;}
.wide-line{float: left;clear: both;border: 2px solid #ddd;width: 100%;}
.hidden{opacity: 0;}
/*_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-*/
.box-padding{padding: 50px 0px;}
.big-font{font-size: 64px;}
.medium-font{font-size: 32px;}
.small-font{font-size: 16px;}
.content{width: 80%;}
.wide-content{width: 90%;}
.container{float: left;width: 100%;}
/*_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-*/
.ref-1{text-decoration: none;color: #222021;font-weight: bold;cursor: pointer;}
.ref-1:Hover{color: #666465;}

.btn-1{background-color: #F39C12;border:none;padding: 10px;color: white;border-radius: 10px;transition: 0.5s;cursor: pointer;}
.btn-1:Hover{box-shadow: 0px 2px 6px 2px #444;}
.btn-1:focus{outline: none;}

.img-wrapper{
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 40px;
}
.img{
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/*_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-*/
.logo{float: left;width: 140.2px;height: 60px;}

.menuMobile-scroll{float: right;width: 60px;height: 60px;background-color: #F39C12;position: relative;display: none;}
.menuMobile-scroll img{width: 60px;height: 60px;position: absolute;;left: 50%;top:50%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);}
.menuMobile-scroll:Hover{background-color: #222021;cursor: pointer;}
/*-------------------------------------*/
/*-------------------------------------*/
.topnav-scroll{list-style-type: none;margin: 0;padding: 0;overflow: hidden;text-align: center;width: 100%;z-index: 100;position: fixed;display: none;background-color: black;top: 0px;}
.topnav-scroll-content{width: 80%;}
.topnav-scroll a {float: left;color: white;display: block;text-align: center;text-decoration: none;line-height: 60px;padding: 0px 10px;}
.topnav-scroll a:hover:not(.active) {text-decoration: underline;}
.activeA {background-color: #F39C12;color: #444 !important;}
/*-------------------------------------*/
/*-------------------------------------*/
.mapWrapper{text-align: center;}
.mapWrapper iframe{float: left;width: 100%;height: 420px;}
/*-------------------------------------*/
/*-------------------------------------*/
@media screen and (max-width: 900px){.each-product{float: left;width: 50%;padding: 10px;}}
@media screen and (max-width: 500px){.each-product{float: left;width: 100%;padding: 10px;}}
@media screen and (max-width: 720px){
    .topnav {display: none;}
    .menuDesktop {display: none;}
    .mobile-menu {display: block !important;}
    .big-font {font-size: 40px;}

    .topnav-scroll a {display: none;}
    .menuMobile-scroll {display: block;}
}
@media screen and (max-width: 500px){
    .some-info h1 {width: 100%;font-size: 32px;}
    .footer-content-side-box {width: 100%;}
}
