.services {
    background-color: #eee;
}
.services .services-box-wrapper {
    float: left;
    width: 25%;
}
.services .services-box {
    float: left;
    background-color: #f0d9b6;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    height: 300px;
}
.services .image {
    float: left;
}
.services h2:first-child {
    float: left;
    width: 25%;
    clear: both;
    padding: 10px;
    background-color: #F39C12;
    color: white;
    text-align: center;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.services h2 {
    float: left;
    clear: both;
    color: #666;
    font-size: 18px;
    margin-top: 10px;
}
.services .text-wrapper {
    float: left;
    background-color: #eee;
    width: 100%;
    height: 100px;
    padding: 10px 0px;
    margin: 10px 0px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.services ul {
    font-size: 16px;
    color: #666465;
}
.services .text-wrapper-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100px;
    background-color: red;
    background: linear-gradient(180deg, transparent 50%, #eee 100%);
}
.services button {
    float: left;
    background-color: transparent;
    border: 1px solid #666;
    color: #666;
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.4s;
}
.services button:Hover {
    background-color: white;
}

@media screen and (max-width: 900px){
    .services .services-box-wrapper {
        width: 50%;
    }
}
@media screen and (max-width: 720px) {
    .services .services-box-wrapper {
        width: 100%;
    }
}