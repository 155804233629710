.tips {
    color: #444;
    position: absolute;
    bottom: -200px;
    z-index: 3;
}
.tips-box {
    float: left;
    width: 25%;
    transition: 0.4s;
}
.tips-box-content {
    text-align: center;
    padding: 40px 0px;
}
.tips .title {
    color: #F39C12;
    padding: 10px 0px;
    font-weight: bold;
}
.tips .par {
    color: #F39C12;
    font-style: italic;
}
.tips .tips-box:Hover {
    cursor: pointer;
    transform: scale(1.2);
}
@media screen and (max-width: 720px) {
    .tips{
        display: none;
    }
}