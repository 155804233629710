.popup-position-for-service {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
}
.popup-position-for-service .popup-content {
    margin: 20px 0px;
}
.popup-wrapper-for-service {
    width: 500px;
    margin: auto;
    margin-top: 5%;
    text-align: left;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    background-color: #eee;
    position: relative;
}
.popup-position-for-service h2 {
    color: #aaa;
    font-size: 30px;
}
.popup-position-for-service p {
    text-indent: 10px;
    margin-top: 20px;
}
.popup-position-for-service .cancel-btn {
    font-size: 30px;
    background-color: #F39C12;
    color: white;
    height: 35px;
    width: 35px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
}
.popup-position-for-service .cancel-btn:active {
    outline:none;
    background-color: #c78111;
}