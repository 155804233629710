.header {
    color: white;
    width: 100%;
    height: 500px;
    position: relative;
    /* background-image: url('../../images/engine.jpg');
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
    margin-bottom: 200px;
}
.header-middle {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    z-index: 1;

    width: 80%;
    font-size: 20px;
    border-left: 4px solid white;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
}
.header button {
    background-color: #F39C12;
    border:none;
    padding: 10px;
    color: white;
    font-size: 12px;
}
.header h1 span {
    color: white;
    padding: 10px;
    font-style: italic;
}
@media screen and (max-width: 720px){
    .header {
        margin-bottom: 0px !important;
    }
    .header p {
        display: none;
    }
    .logo {
        display: none;
    }
}