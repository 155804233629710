.mobile-menu {
    display: none;
    z-index: 100;
    background-color: black;
}
.mobile-menu img {
    float: left;
    width: 140.2px;
    height: 60px;
}
.menuMobile {
    float: right;
    width: 60px;
    height: 60px;
    position: relative;
}
.menuMobile:Hover {
    background-color: #222021;
    cursor: pointer;
}
.menu-icon {
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.nav-open {
    background-image: url('../../images/icons/nav-open.png');
}
.nav-close {
    background-image: url('../../images/icons/nav-close.png');
}




.overlay {
    width: 100%;
    z-index: 10;
    background-color: #222;
    top: 60px;
    position: absolute;
}
.overlay .overlay-content {
    text-align: center;
}
.overlay a {
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
    line-height: 40px;
}
.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}