.topnav, .topnav-scroll {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.topnav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    width: 100%;
    z-index: 10;
}
.topnav-absolute {
    position: absolute;
    background-color: transparent;
}
.topnav-normal {
    position: normal;
    background-color: #000 !important;
}
.topnav a {
    float: left;
    display: block;
    text-align: center;
    text-decoration: none;
    line-height: 60px;
    padding: 0px 10px;
    color: white;
}
.topnav a:hover:not(.active) {
    text-decoration: underline;
}
.activeA {
    background-color: #F39C12;
    color: #444 !important;
}