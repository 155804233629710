.below-footer {
    float: left;
    width: 100%;
    background-color: #222021;
    font-size: 16px;
    color: white;
    padding: 40px 0px;
}
.below-footer-content {
    width: 80%;
}
.below-footer p {
    color: #666;
    text-decoration: none;
}
.below-footer a {
    color: #fff;
    text-decoration: none;
}